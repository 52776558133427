body {
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .container {
    text-align: center;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
  }
  
  .header {
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  .note {
    font-size: 16px;
    color: #666;
    margin-top: 10px;
  }
  
  .footer {
    font-size: 14px;
    color: #999;
    margin-top: 20px;
  }
  