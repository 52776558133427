@media only screen and (orientation: landscape) {

  }
  
  /* CSS for portrait orientation */
  
  @media only screen and (orientation: portrait) {
    .admin-nav-bar {
        max-width: 100vw;
        overflow-x: scroll;
    }
    .admin-nav-bar .btn {
        white-space: nowrap;
    }
  }

  .admin-page .page-container {
    height: 90vh;
  }
  